<template>
  <div class="wameed-dashboard-page-content">


    <section class="wameed-dashboard-page-content_body">

      <div class="work-card">
        <div class="work-card__header">
          <div class="work-card__header_info">
            <div class="title">{{ $t('settings.work.general') }}</div>
            <div v-if="workDays.status===1">
              <span class="status open"> {{ $t('days.open') }} </span>
            </div>
            <div v-else>
              <span class="status close"> {{ $t('days.close') }} </span>
            </div>
          </div>
          <div class="switch">
            <WameedSwitch
                :checked="workDays.status ===1"
                @onChange="toggleGeneralStatus"
            />
          </div>
        </div>
      </div>


      <template v-if="workDays.days||true">

        <work-time-card
            title="sat"
            :day="workDays.days.sat"
            @onAdd="()=>showModal(1)"
            @onDelete="showDelete"
        />
        <work-time-card
            title="sun"
            :day="workDays.days.sun"
            @onAdd="()=>showModal(2)"
            @onDelete="showDelete"
        />
        <work-time-card
            title="mon"
            :day="workDays.days.mon"
            @onAdd="()=>showModal(3)"
            @onDelete="showDelete"
        />

        <work-time-card
            title="tue"
            :day="workDays.days.tue"
            @onAdd="()=>showModal(4)"
            @onDelete="showDelete"
        />
        <work-time-card
            title="wed"
            :day="workDays.days.wed"
            @onAdd="()=>showModal(5)"
            @onDelete="showDelete"
        />
        <work-time-card
            title="thur"
            :day="workDays.days.thur"
            @onAdd="()=>showModal(6)"
            @onDelete="showDelete"
        />

        <work-time-card
            title="fri"
            :day="workDays.days.fri"
            @onAdd="()=>showModal(7)"
            @onDelete="showDelete"
        />

      </template>


      <work-time-modal
          :visible="addModal"
          :day="modalDay"
          @close="addModal = false;modalDay=0"
      />

      <warning-modal
          variant="danger"
          iconName="trash-icon"
          :visible="deleteModal"
          @close="deleteModal = false"
          @submitAction="deleteAction"
          :title="$t('settings.work.modal.delete')"
          :subTitle="$t('settings.work.modal.delete_desc')"
          :btnTitle="$t('btn.delete')"
      />

    </section>
  </div>
</template>

<script>


import WorkTimeModal from "@/views/pages/users/vendors/details/workdays/components/workTimeModal";
import {WameedSwitch} from 'wameed-ui/dist/wameed-ui.esm';
import {mapGetters} from "vuex";
import WorkTimeCard from "@/views/pages/users/vendors/details/workdays/components/workTimeCard";
import WarningModal from "@/components/WarningModal";

export default {
  components: {
    WarningModal,
    WorkTimeModal,
    WorkTimeCard,
    WameedSwitch,
  },
  data() {
    return {
      vendor_id:0,
      addModal: false,
      deleteModal: false,
      modalDay: 0,
      deleteShiftId: 0,
    }
  },
  computed: {
    ...mapGetters({
      workDays: 'vendor/workDays/getData',
    }),
  },

  methods: {
    toggleGeneralStatus() {
      let status = this.workDays.status === 1 ? 0 : 1;
      let form = {
        day: 'all',
        status: status,
        vendor_id:this.vendor_id

      }
      this.$store.dispatch('vendor/workDays/toggle', form);
    },
    showModal(day) {
      this.modalDay = day;
      this.addModal = true;
    },
    showDelete(id) {
      this.deleteShiftId = id;
      this.deleteModal = true;
    },
    deleteAction() {
      this.deleteModal = false
      let form = {
        id: this.deleteShiftId,
        vendor_id:this.vendor_id
      }
      this.$store.dispatch('vendor/workDays/delete', form);
    }
  },
  mounted() {
    this.vendor_id = this.$route.params.id;
    this.$store.dispatch('vendor/workDays/get',{vendor_id:this.vendor_id})
  }

};
</script>
